@font-face {
font-family: '__whitney_c9fc18';
src: url(/_next/static/media/c476f93dc811aada-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__whitney_c9fc18';
src: url(/_next/static/media/7304e6f21617f5cc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__whitney_c9fc18';
src: url(/_next/static/media/cebb492a7f0542fb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__whitney_c9fc18';
src: url(/_next/static/media/d1be250b157125a3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}@font-face {font-family: '__whitney_Fallback_c9fc18';src: local("Arial");ascent-override: 102.66%;descent-override: 25.66%;line-gap-override: 0.00%;size-adjust: 93.52%
}.__className_c9fc18 {font-family: '__whitney_c9fc18', '__whitney_Fallback_c9fc18'
}.__variable_c9fc18 {--font-whitney: '__whitney_c9fc18', '__whitney_Fallback_c9fc18'
}

